import React, {  useState } from "react";
import { useLocation } from 'react-router-dom';
// import { appGetHome } from "../../services/HomeService";
// import { HOME_API_STALE_TIME } from "../../utils/Constant";

import DesktopHeader from "./DesktopHeader";
import "./Header.css";
import MobileHeader from "./MobileHeader";
import DownloadApp from "./DownloadApp";
import HeaderTopContent from "./HeaderTopContent";

// import { useQuery } from "react-query";
// import menu_data from "../../data/menu_data.json";
import useMobileDevice from "../../hooks/useMobileDevice";

function Header({ mobileHeaderOptions,showHeaderstatus=true }) {
  const { isMobile } = useMobileDevice();
  const [catMenus] = useState([]);
  const location = useLocation();

  const getCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return false;
  }  
  const [closeDownloadApp, setcloseDownloadApp] = useState(getCookie("close_download_app"));
  const [ishomepage] = useState((location.pathname === "/") ? true : false);

  const removeDownloadPopup = () => {    
    setcloseDownloadApp(true);
  }
  // const { data } = useQuery("homeData", () =>
  //   appGetHome().then((res) => {
  //     res = JSON.parse(res?.data);
  //     return res;
  //   }),
  //   {
  //     staleTime: HOME_API_STALE_TIME,
  //   }
  // );

  // useEffect(() => {
  //   setCatMenus(data?.Menu_Categories || []);
  // }, [data]);

  return (
    <div id="header">
      {ishomepage ? (
          <HeaderTopContent/>
        ) 
        : 
        ""}
      {isMobile === true ? (
        <>
          {closeDownloadApp ? 
            (
              ""
            )
            :
            (
              <>
                {ishomepage ?
                  (
                     <DownloadApp removeDownloadPopup={removeDownloadPopup} />
                  )
                  :
                  (
                    ""
                  )
                }
              </>
            )
          }
          <MobileHeader
            catMenus={catMenus}
            mobileHeaderOptions={mobileHeaderOptions}
          />
        </>
      ) : (
        <DesktopHeader catMenus={catMenus} showHeaderstatus={showHeaderstatus}/>
      )
      }
    </div>
  );
}

export default Header;
