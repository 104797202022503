import { useState } from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import {
  ErrorsMsg,
  PLEASE_WAIT_TXT,
  REGEX_STRONG_PASSWORD,
} from "../../utils/Constant";
import { appResetPassword } from "../../services/AccountServices";

const schema = yup.object().shape({
  password: yup
    .string()
    .required(ErrorsMsg.required)
    .matches(REGEX_STRONG_PASSWORD, ErrorsMsg.strongPwd),
  confirm_password: yup
    .string()
    .required(ErrorsMsg.required)
    .oneOf([yup.ref("password"), null], ErrorsMsg.matchPwd),
});

function ResetPasswordOTP({
  valid = false,
  customerId,
  token,
  handleSetStep,
  handleSetCustomerId,
  handleSetToken,
  toggleAuthFormCb,
}) {
  const [pageValid] = useState(valid);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const onSubmitSuccess = (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    if (customerId && token) {
      appResetPassword(customerId, token, values?.password).then(
        (res) => {
          res = JSON.parse(res?.data);
          if (res?.status === "success") {
            setSuccessMsg(res?.message);
            handleSetCustomerId(null);
            handleSetToken(null);
          } else {
            setErrorMsg(res?.message);
          }
        }
      );
    }
  };

  return (
    <div className="reset-pwd-otp-container">
      {pageValid && customerId && token ? (
        <Formik
          validationSchema={schema}
          onSubmit={onSubmitSuccess}
          initialValues={{
            password: "",
            confirm_password: "",
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit} noValidate autoComplete="off">
              <FloatingLabel
                controlId="password"
                label="Enter new password"
                className="mb-3"
              >
                <Form.Control
                  type="password"
                  name="password"
                  autoComplete="off"
                  placeholder="Enter new password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.password && errors.password}
                />

                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </FloatingLabel>

              <FloatingLabel
                controlId="confirm_password"
                label="Confirm new password"
                className="mb-3"
              >
                <Form.Control
                  type="password"
                  name="confirm_password"
                  autoComplete="off"
                  placeholder="Confirm new password"
                  value={values.confirm_password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={
                    touched.confirm_password && errors.confirm_password
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {errors.confirm_password}
                </Form.Control.Feedback>
              </FloatingLabel>
              <button
                className="btn btn-primary mt-35"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? PLEASE_WAIT_TXT : "Reset Password"}
              </button>
            </Form>
          )}
        </Formik>
      ) : (
        <>
          {errorMsg.length > 0 && (
            <div className="validation-advice" style={{ color: "#eb340a" }}>
              {errorMsg}
            </div>
          )}

          {successMsg.length > 0 && (
            <div className="validation-advice" style={{ color: "#155724" }}>
              {successMsg}
            </div>
          )}

          <button
            className="btn btn-primary mt-35"
            type="button"
            onClick={(e) => {
              toggleAuthFormCb("login");
            }}
          >
            LOGIN
          </button>
        </>
      )}
    </div>
  );
}

export default ResetPasswordOTP;
