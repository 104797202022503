import { useEffect, useState } from "react";
import CustomSelect from "../Common/CustomSelect";

function CartItemOptionHtml({
  cartItem,
  option,
  onQuantityChange,
  onSizeChange,
}) {
  const [html, setHtml] = useState("");

  useEffect(() => {
    if (option.name === "Quantity") {      
      if(cartItem?.available_quantity > '5'){
        cartItem.available_quantity = 5;
      }else if(cartItem?.available_quantity < '0'){
        cartItem.available_quantity = 1;
      }
      setHtml(
        <div className="size-dp">
          <p>Qty: </p>
          <CustomSelect
            /**
             * BOC 
             * @TicketId: Task#136939 - Changes in Size Guide
             * @author: Mohammad Meraj
             * @date: 28-Feb-2023
             * @use: Convert the int value to the string because he have updated the design in the custom select library to manage the size drop down
             */
            options={[...Array(cartItem?.available_quantity)].map((i, qty) => {
              return { value: qty + 1, label: ''+(qty + 1) };
            })}
            onChange={(e) => onQuantityChange(e, cartItem)}
            defaultValue={option?.value}
            placeholder=""
            text_color="#949494"
            className="cs-ddl-cart"
          />
          {/* <select
            onChange={(e) => onQuantityChange(e.target, cartItem)}
            value={option?.value.toString()}
          >
            {[...Array(cartItem?.available_quantity)].map((i, qty) => {
              return (
                <option
                  key={`qty-${qty}`}
                  value={qty + 1}
                  // selected={qty + 1 === option?.value}
                >
                  {qty + 1}
                </option>
              );
            })}
          </select> */}
        </div>
      );
    } else if (option.name === "Size") {
      setHtml(
        <div className="size-dp">
          <p>Size: </p>
          <CustomSelect
            options={cartItem?.available_sizes?.map((size, indx) => {
              return { value: size?.id, label: size?.value };
            })}
            onChange={(e) => onSizeChange(e, cartItem)}
            defaultValue={
              cartItem?.available_sizes?.find(
                (op) => op.value === option?.value
              )?.id || ""
            }
            placeholder=""
            text_color="#949494"
            className="cs-ddl-cart"
          />
          {/* <select
            onChange={(e) => onSizeChange(e.target, cartItem)}
            value={option?.value.toString()}
          >
            {cartItem?.available_sizes?.map((size, indx) => {
              return (
                <option
                  key={`size-${indx}`}
                  value={size?.id}
                  // selected={size?.value.toString() === option?.value.toString()}
                >
                  {size?.value}
                </option>
              );
            })}
          </select> */}
        </div>
      );
    } else {
      setHtml(
        // <div className="size-dp">
        //   <p>{option?.name}: </p>
        //   <span>{option?.value}</span>
        // </div>
        ""
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItem, option.name, option.value]);

  return <>{html}</>;
}

export default CartItemOptionHtml;
