import { useEffect } from "react";
import { useState } from "react";
import { MOBIEL_VIEW_WIDTH } from "../utils/Constant";
import useWindowSize from "./useWindowSize";

const useMobileDevice = () => {
  const { width } = useWindowSize();

  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= MOBIEL_VIEW_WIDTH
  );
  
  useEffect(() => {
    setIsMobile(width <= MOBIEL_VIEW_WIDTH);
  }, [width]);

  return { isMobile };
};

export default useMobileDevice;
