import { ADD_OPEN_POPUP, CLEAR_OPEN_POPUP } from "../actions/types";

// SET Popup Id to Open
export const setOpenPoup = (popupId) => {    
    document.body.setAttribute('style',`position: fixed; top: -${window.scrollY}px;left:0;right:0;`);
    return {
        type: ADD_OPEN_POPUP,
        payload: {popupId}
    }
}


// CLEAR POPUP ID
export const clearPopups = () => {
    document.body.setAttribute('style',''); 
    return {
        type: CLEAR_OPEN_POPUP
    }
}