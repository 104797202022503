import React from "react";
import "./EmptyCart.css";

function EmptyCart({ sideCartModalCb }) {
  return (
    <div className="empty-cart-text" id="empty_cart">
      <img
        src={
          process.env.PUBLIC_URL + "/assets/images/icons/add-shopping-bag.svg"
        }
        alt=""
      />
      <p>
        Looks like you haven’t made your choice yet.<br />Let’s get started!
      </p>
      <button
        className="btn-primary mt-20 empty-cart-button"
        onClick={() => sideCartModalCb(false)}
      >
        CONTINUE SHOPPING
      </button>
    </div>
  );
}

export default EmptyCart;
