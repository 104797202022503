import { useState } from "react";
import { useNavigate } from "react-router-dom";
import mobile_menu_data from "../../data/mobile_menu_data.json";
// import mobile_menu_data from "../../data/desktop_menu_data.json";

function SearchCategoryAccordion({ modalToggle }) {
  const navigate = useNavigate();

  const menMenus =
    mobile_menu_data?.find((e) => e.name?.toLowerCase() === "men")
      ?.second_children || [];
  const womenMenus =
    mobile_menu_data?.find((e) => e.name?.toLowerCase() === "women")
      ?.second_children || [];
  const kidsMenus =
    mobile_menu_data?.find((e) => e.name?.toLowerCase() === "kids")
      ?.second_children || [];

  const [activeTab, setActiveTab] = useState("men");

  // const { data } = useQuery(
  //   "homeData",
  //   () =>
  //     appGetHome().then((res) => {
  //       res = JSON.parse(res?.data);
  //       return res;
  //     }),
  //   {
  //     staleTime: HOME_API_STALE_TIME,
  //   }
  // );

  // useEffect(() => {
  //   const catmenus = menu_data || [];
  //   setMenMenus(
  //     catmenus?.find((e) => e.name?.toLowerCase() === "men")?.second_children ||
  //       []
  //   );
  //   setWomenMenus(
  //     catmenus?.find((e) => e.name?.toLowerCase() === "women")
  //       ?.second_children || []
  //   );
  //   setKidsMenus(
  //     catmenus?.find((e) => e.name?.toLowerCase() === "kids")
  //       ?.second_children || []
  //   );
  // }, []);

  const onCategoryClick = (url) => {
    navigate(url);
    modalToggle();
    return;
  };

  return (
    <div className="search-categories">
      <ul className="search-tab">
        <li className={activeTab === "men" ? " active" : ""}>
          <button className="btn-blank" onClick={(e) => setActiveTab("men")}>
            Men
          </button>
        </li>
        <li className={activeTab === "women" ? " active" : ""}>
          <button className="btn-blank" onClick={(e) => setActiveTab("women")}>
            Women
          </button>
        </li>
        <li className={activeTab === "kids" ? " active" : ""}>
          <button className="btn-blank" onClick={(e) => setActiveTab("kids")}>
            Kids
          </button>
        </li>
      </ul>

      {activeTab === "men" && (
        <div id="men-search" style={{ display: "block" }}>
          {menMenus?.length > 0 && (
            <div className="accordion" id={`searchmenumen`}>
              {menMenus?.map((secondChild, sIdx) => {
                return (
                  <div key={`sub-menu-item-${sIdx}`}>
                    {secondChild?.children &&
                    secondChild?.children?.length > 0 ? (
                      <div className="accordion-item">
                        <h2
                          className="accordion-header"
                          id={`${secondChild?.name}menu`}
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#${secondChild?.name}collapse`}
                            aria-expanded="false"
                            aria-controls={`${secondChild?.name}collapse`}
                          >
                            <b>{secondChild?.name}</b>
                          </button>
                        </h2>
                        <div
                          id={`${secondChild?.name}collapse`}
                          className="accordion-collapse collapse"
                          aria-labelledby={`${secondChild?.name}menu`}
                          data-bs-parent={`#searchmenumen`}
                        >
                          {secondChild?.children &&
                          secondChild?.children?.length > 0 ? (
                            <div className="accordion-body text-left">
                              {secondChild?.children?.map(
                                (child, childIndex) => {
                                  return (
                                    <p
                                      className={`${
                                        childIndex > 0 ? "mt-10" : ""
                                      } cursor-pointer`}
                                      key={`subSubMenuitem-${childIndex}`}
                                    >
                                      <span
                                        className="text-grey"
                                        onClick={(e) =>
                                          onCategoryClick(
                                            `/catalog/${child?.url_path}`
                                          )
                                        }
                                      >
                                        {child?.name}
                                      </span>
                                    </p>
                                  );
                                }
                              )}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : (
                      <p className="designer-item">
                        <span
                          className="text-grey"
                          onClick={(e) =>
                            onCategoryClick(`/catalog/${secondChild?.url_path}`)
                          }
                        >
                          {secondChild?.name}
                        </span>
                      </p>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}

      {activeTab === "women" && (
        <div id="women-search" style={{ display: "block" }}>
          {womenMenus?.length > 0 && (
            <div className="accordion" id={`searchmenuwomen`}>
              {womenMenus?.map((secondChild, sIdx) => {
                return (
                  <div key={`sub-menu-item-${sIdx}`}>
                    {secondChild?.children &&
                    secondChild?.children?.length > 0 ? (
                      <div className="accordion-item">
                        <h2
                          className="accordion-header"
                          id={`${secondChild?.name}menu`}
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#${secondChild?.name}collapse`}
                            aria-expanded="false"
                            aria-controls={`${secondChild?.name}collapse`}
                          >
                            <b>{secondChild?.name}</b>
                          </button>
                        </h2>
                        <div
                          id={`${secondChild?.name}collapse`}
                          className="accordion-collapse collapse"
                          aria-labelledby={`${secondChild?.name}menu`}
                          data-bs-parent={`#searchmenuwomen`}
                        >
                          {secondChild?.children &&
                          secondChild?.children?.length > 0 ? (
                            <div className="accordion-body text-left">
                              {secondChild?.children?.map(
                                (child, childIndex) => {
                                  return (
                                    <p
                                      className={`${
                                        childIndex > 0 ? "mt-10" : ""
                                      } cursor-pointer`}
                                      key={`subSubMenuitem-${childIndex}`}
                                    >
                                      <span
                                        className="text-grey"
                                        onClick={(e) =>
                                          onCategoryClick(
                                            `/catalog/${child?.url_path}`
                                          )
                                        }
                                      >
                                        {child?.name}
                                      </span>
                                    </p>
                                  );
                                }
                              )}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : (
                      <p className="designer-item">
                        <span
                          className="text-grey"
                          onClick={(e) =>
                            onCategoryClick(`/catalog/${secondChild?.url_path}`)
                          }
                        >
                          {secondChild?.name}
                        </span>
                      </p>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}

      {activeTab === "kids" && (
        <div id="kids-search" style={{ display: "block" }}>
          {kidsMenus?.length > 0 && (
            <div className="accordion" id={`searchmenukids`}>
              {kidsMenus?.map((secondChild, sIdx) => {
                return (
                  <div key={`sub-menu-item-${sIdx}`}>
                    {secondChild?.children &&
                    secondChild?.children?.length > 0 ? (
                      <div className="accordion-item">
                        <h2
                          className="accordion-header"
                          id={`${secondChild?.name}menu`}
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#${secondChild?.name}collapse`}
                            aria-expanded="false"
                            aria-controls={`${secondChild?.name}collapse`}
                          >
                            <b>{secondChild?.name}</b>
                          </button>
                        </h2>
                        <div
                          id={`${secondChild?.name}collapse`}
                          className="accordion-collapse collapse"
                          aria-labelledby={`${secondChild?.name}menu`}
                          data-bs-parent={`#searchmenukids`}
                        >
                          {secondChild?.children &&
                          secondChild?.children?.length > 0 ? (
                            <div className="accordion-body text-left">
                              {secondChild?.children?.map(
                                (child, childIndex) => {
                                  return (
                                    <p
                                      className={`${
                                        childIndex > 0 ? "mt-10" : ""
                                      } cursor-pointer`}
                                      key={`subSubMenuitem-${childIndex}`}
                                    >
                                      <span
                                        className="text-grey"
                                        onClick={(e) =>
                                          onCategoryClick(
                                            `/catalog/${child?.url_path}`
                                          )
                                        }
                                      >
                                        {child?.name}
                                      </span>
                                    </p>
                                  );
                                }
                              )}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : (
                      <p className="designer-item">
                        <span
                          className="text-grey"
                          onClick={(e) =>
                            onCategoryClick(`/catalog/${secondChild?.url_path}`)
                          }
                        >
                          {secondChild?.name}
                        </span>
                      </p>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default SearchCategoryAccordion;
