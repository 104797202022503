import { LS_CART_COUNT } from "../../utils/Constant";
import { ADD_CART_COUNT, CLEAR_CART_COUNT, GET_CART_COUNT } from "../actions/types";

const initialState = {
  cartCount: 0
};

/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_CART_COUNT:
         localStorage.setItem(
           LS_CART_COUNT,
           JSON.stringify(action.payload.cartCount)
         );
      return {
        cartCount: action.payload.cartCount,
      };

    case GET_CART_COUNT:
      // let cart_count =
      //   JSON.parse(localStorage.getItem(LS_CART_COUNT)) || 0; // already added products
      let cart_count =
        localStorage.getItem(LS_CART_COUNT) !== "undefined" ? JSON.parse(localStorage.getItem(LS_CART_COUNT)) : 0; // already added products
      return {
        cartCount: cart_count,
      };

    case CLEAR_CART_COUNT:
      localStorage.removeItem(
        LS_CART_COUNT
      );
      return {
        cartCount: 0,
      };
    default:
      // state.cartCount = JSON.parse(localStorage.getItem(LS_CART_COUNT)) || 0;
      state.cartCount = localStorage.getItem(LS_CART_COUNT) !== "undefined" ? JSON.parse(localStorage.getItem(LS_CART_COUNT)) : 0;
      return state;
  }
}
