import React from 'react'

function Newsletter() {
    return (
      <div className="newsletter" id="newsletter">
        <div className="bg"></div>
        <div className="center-box">
          <div className="newsletter-heading">
            <h3>NEWSLETTER</h3>
            <button className="btn-blank">
              <img
                src={process.env.PUBLIC_URL + "/assets/images/icons/cross.svg"}
                className="close-btn-newsletter"
                alt=""
              />
            </button>
          </div>
          <div className="newsletter-body mt-55">
            <p>
              Darveys provide <b>Upto 60% Off</b> on{" "}
              <b>120+ Luxury Brands Directly Sourced</b> from Authorised Stores
              accross <b>USA &amp; Europe</b>.
            </p>
            <p className="mt-35">
              Our <b>Authenticity Guarantee</b> extends to{" "}
              <b>2x The Price In Return</b>.
            </p>
            <p className="mt-35">
              To know more, read about us in{" "}
              <b>Forbes &amp; The Economic Times</b>.
            </p>
            <button className="btn btn-bordered mt-20">SIGN UP</button>
            <button className="btn btn-primary mt-15">LOGIN</button>
          </div>
        </div>
      </div>
    );
}

export default Newsletter
