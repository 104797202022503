import { GET_RECENTLY_VIEWED_PRODUCTS, ADD_RECENTLY_VIEWED_PRODUCTS, CLEAR_RECENTLY_VIEWED_PRODUCTS } from "../actions/types";

// load RECENTLY VIEWED PRODUCTS
export const loadRecentlyViewedProducts = () => {
    return {
        type: GET_RECENTLY_VIEWED_PRODUCTS,
    }
}

// ADD RECENTLY VIEWED PRODUCT
export const addRecentlyViewedProducts = (product) => {
    return {
        type: ADD_RECENTLY_VIEWED_PRODUCTS,
        payload: {product: product}
    }
}

// CLEAR RECENTLY VIEWED PRODUCTS
export const clearRecentlyViewedProducts = () => {
    return {
        type: CLEAR_RECENTLY_VIEWED_PRODUCTS
    }
}