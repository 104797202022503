import { useEffect, useState } from "react";

function useScroll(initialState = false) {
  const [isScroll, setIsScroll] = useState(initialState);
  useEffect(() => {
    const handleScroll = () => {
      setIsScroll(window.scrollY > 90);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return [isScroll];
}

export default useScroll;
