import React from 'react'

function Copyright() {

    const currentYear = new Date().getFullYear();

    return (
      <div className="copyright-text bg-light">
        <div className="container-fluid">
          <div className="row">
            <div className="text">
              <p>
                copyright @ {currentYear} <a href="#0" style={{pointerEvents:"none"}}>Darveys.com</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
}

export default Copyright
