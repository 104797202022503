import { combineReducers } from "redux";
import errorReducer from './errorReducer';
import authReducer from './authReducer';
import recentlyViewedProductsReducer from "./recentlyViewedProductsReducer";
import popupReducer from "./popupReducer";
import cartReducer from "./cartReducer";

// Here we are combining all the reducers as one root reducer
export default combineReducers({
    error: errorReducer,
    auth: authReducer,
    recentViewedProduct: recentlyViewedProductsReducer,
    popup: popupReducer,
    cartCount: cartReducer 
});
