import config from "../config/app";
import * as API from "../utils/ApiHelper";

const { isProd } = config;

const API_ENDPOINT = isProd
    ? config.production.api_endpoint
    : config.development.api_endpoint;

// Get SEO Details
export const appGetSEODetails = (postData) => {
    const url = `${API_ENDPOINT}/appGetSEODetails`;

    // preparing post data to send
    let data = {
        page_url: "",
        ...postData,
    };
    return API.post(url, data);
};