import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import config from "../../config/app.js";

function DesignerNav({ menu }) {
  const [isHovered, setIsHovered] = useState(false);

 useEffect(() => {
   if (isHovered) {
     window.$(".bg-header").css("display", "block");
   } else {
     window.$(".bg-header").css("display", "none");
   }
 }, [isHovered]);

  return (
    <>
      <li
        className={`hasChildren ${isHovered ? " hover" : ""}`}
        onMouseEnter={(e) => setIsHovered(true)}
        onMouseLeave={(e) => setIsHovered(false)}
      >
        {menu?.id ? (
          <Link
            to={`/catalog/${menu?.url_path}`}
            className=" site-nav__item"
            onClick={(e) => setIsHovered(false)}
          >
            {menu?.name}
          </Link>
        ) : (
          <Link to={`/designers/`} className=" site-nav__item">
            {menu?.name}
          </Link>
        )}

        {menu?.second_children && menu?.second_children?.length > 0 && (
          <div className="darveys-nav-children nav-children designers-nav">
            <div className="child-row">
              <div className="darveys-child-col child-col col-1">
                <>
                  {
                    <>
                      <div className={`list-nav-inner`}>
                        <h6>{menu?.second_children[0]?.name}</h6>
                        {menu?.second_children[0]?.children?.map(
                          (child, childIndex) => {
                            return (
                              <p
                                className={`inner-item ${
                                  childIndex === 0 ? "mt-1" : ""
                                }`}
                                key={`subSubMenu-${childIndex}`}
                              >
                                <Link
                                  to={`/catalog/${child?.url_path}`}
                                  onClick={(e) => setIsHovered(false)}
                                >
                                  {child?.name}
                                </Link>
                              </p>
                            );
                          }
                        )}
                      </div>
                    </>
                  }
                </>
              </div>

              <div className="darveys-child-col child-col col-1">
                <>
                  {
                    <>
                      <div className={`list-nav-inner`}>
                        <h6>{menu?.second_children[1]?.name}</h6>
                        {menu?.second_children[1]?.children?.map(
                          (child, childIndex) => {
                            return (
                              <p
                                className={`inner-item ${
                                  childIndex === 0 ? "mt-1" : ""
                                }`}
                                key={`subSubMenu-${childIndex}`}
                              >
                                <Link
                                  to={`/catalog/${child?.url_path}`}
                                  onClick={(e) => setIsHovered(false)}
                                >
                                  {child?.name}
                                </Link>
                              </p>
                            );
                          }
                        )}
                      </div>
                    </>
                  }
                </>
              </div>

              <div className="darveys-child-col child-col col-w">
                <>
                  {
                    <>
                      <div className={`list-nav-inner`}>
                        <h6>{menu?.second_children[2]?.name}</h6>
                        <div className="menu-felx-designers pt-3">
                          {menu?.second_children[2]?.children?.map(
                            (child, childIndex) => {
                              return (
                                <div
                                  className="inner-flex-item"
                                  key={`subSubMenu-${childIndex}`}
                                >
                                  <p className={`inner-item`}>
                                    {child?.url_path ? (
                                      <Link
                                        to={`/designers/${child?.url_path}`}
                                        onClick={(e) => setIsHovered(false)}
                                      >
                                        {child?.name}
                                      </Link>
                                    ) : (
                                      <Link
                                        to={`/designers`}
                                        onClick={(e) => setIsHovered(false)}
                                      >
                                        {child?.name}
                                      </Link>
                                    )}
                                  </p>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </>
                  }
                </>
              </div>

              <div className="darveys-image-menu">
                {menu?.image_data?.map((imageData, imageIdx) => {
                  return (
                    <div
                      className="darveys-image-sub-menu"
                      key={`subimageMenu-${imageIdx}`}
                    >
                      <Link to={`/catalog/${imageData?.url_path}`}>
                        {/* <img
                          src={process.env.PUBLIC_URL + imageData?.src} 
                          alt=""
                        /> */}
                        <LazyLoadImage
                          alt={imageData?.title}
                          effect="blur"
                          src={config.skinURL + imageData?.src || ""}
                        />
                      </Link>
                      <div className="inner-img-text">
                        <h6>{imageData?.title}</h6>
                        <p className="inner-item mt-1">
                          <Link to={`/catalog/${imageData?.url_path}`}>
                            Shop Now
                          </Link>
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </li>
    </>
  );
}

export default DesignerNav;
