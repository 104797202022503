import React from "react";
import { useDispatch } from "react-redux";
import { FloatingLabel, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { ErrorsMsg, PLEASE_WAIT_TXT } from "../../utils/Constant";
import { socialLogin } from '../../store/actions/authActions';
import { clearPopups } from "../../store/actions/popupActions";

const schema = yup.object().shape({
  email: yup.string().required(ErrorsMsg.required),
});

function EmailAuth({active, toggleAuthFormCb,appleResponse}) {
  const dispatch = useDispatch();
  if (!active) return null;

  const onSubmitSuccess = (values, { setSubmitting }) => {
      const userDetails = {
        apple_id: appleResponse?.authorization?.code || "",
        first_name: appleResponse?.user?.name?.firstName || "",
        last_name: appleResponse?.user?.name?.lastName || "",
        telephone: "",
        country_id: "",
        email: values?.email,
        dob: "",
        password: "",
        user_type: "guest",
        social_login: "yes",
        is_seller: "0",
        city: "",
        postcode: "",
        company: "",
        confirm: "",
        fax: "",
        address_1: "",
        address_2: "",
      };

    dispatch(socialLogin(userDetails, "apple")).then((data) => {
      if (!data) {
        setSubmitting(false);
      }
      dispatch(clearPopups());
    })
    .finally(() => {
      setSubmitting(false);
    });
    
  };


  return (
    <>
      <Formik
        validationSchema={schema}
        onSubmit={onSubmitSuccess}
        initialValues={{
          email: "",
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} noValidate autoComplete="off">
            <FloatingLabel
              controlId="email"
              label="Email"
              className="mb-3"
            >
              <Form.Control
                type="email"
                name="email"
                autoComplete="off"
                placeholder="name@example.com"
                value={values.email}
                onChange={handleChange}
                // onBlur={handleBlur}
                isInvalid={touched.email && errors.email}
              />

              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </FloatingLabel>            
            <button
              className="btn btn-primary mt-35"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? PLEASE_WAIT_TXT : "SUBMIT"}
            </button>            
          </Form>
        )}
      </Formik>
    </>
  );
}

export default EmailAuth;
