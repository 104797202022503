import config from "../config/app";
import * as API from "../utils/ApiHelper";

const { isProd } = config;

const API_ENDPOINT = isProd
  ? config.production.api_endpoint
  : config.development.api_endpoint;

// Add to Cart
export const appAddToCart = (data) => {
  const url = `${API_ENDPOINT}/appAddToCart`;

  return API.post(url, {
    cart_products: JSON.stringify(data),
  });
};

// Add to Cart
export const appGetCartDetails = () => {
  let url = `${API_ENDPOINT}/appGetCartDetails`;

  return API.post(url, {});
};

// Remove Product from Cart
// cartProducts: [{\"cart_id\":\"606581\",\"quantity\":\"0\",\"id_product_attribute\":\"0\",\"id_customization_field\":\"\"}]
export const appRemoveProduct = (cartProducts) => {
  const url = `${API_ENDPOINT}/appRemoveProduct`;

  // preparing post data to send
  let data = {
    request_type: "remove_product",
    pp_shippings: "[]",
    cart_products: cartProducts,
  };

  return API.post(url, data);
};

// Update Product Quantity in Cart
// cartProducts: [{\"cart_id\":\"606581\",\"quantity\":\"0\",\"id_product_attribute\":\"0\",\"id_customization_field\":\"\"}]
export const appUpdateCartQuantity = (cartProducts) => {
  const url = `${API_ENDPOINT}/appUpdateCartQuantity`;

  // preparing post data to send
  let data = {
    request_type: "update_product",
    pp_shippings: "[]",
    cart_products: cartProducts,
  };

  return API.post(url, data);
};

// Update Product Size in Cart
// data: {"size_id":"2320","cart_id":"606595","color_id":"2101","product_id":"794764"}
export const appUpdateProductSize = (data) => {
  const url = `${API_ENDPOINT}/appUpdateProductSize`;
  return API.post(url, data);
};
