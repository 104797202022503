import config from "../config/app";
import * as API from "../utils/ApiHelper";

const { isProd } = config;

const API_ENDPOINT = isProd
  ? config.production.api_endpoint
  : config.development.api_endpoint;

// Get Category Details
export const appGetCategoryDetails = (data) => {
  const url = `${API_ENDPOINT}/appGetCategoryDetails`;

  // preparing post data to send
  data = {
    item_count: 30,
    filter: "",
    page_number: 1,
    sort_by: "relevance",
    ...data,
  };

  return API.post(url, data);
};

// Get Filters
export const appGetFilters = (data) => {
  const url = `${API_ENDPOINT}/appGetFilters2`;

  // preparing post data to send
  data = {
    ...data,
  };

  return API.post(url, data);
};

// Get Search Results
export const appGetSearchResults = (search_term) => {
  const url = `${API_ENDPOINT}/appGetSearchResults`;

  // preparing post data to send
  let data = {
    search_term: search_term,
  };

  return API.post(url, data);
};

export const appGetDailyDealProductDetails = (data) => {
  const url = `${API_ENDPOINT}/appGetDailyDealProductDetails`;

  return API.post(url, data);
};
