import moment from "moment";
export const formatDate = (
  date,
  formatToChange,
  currentFormat = "DD/MM/YY"
) => {
  try {
    let isValid = moment(date, currentFormat).isValid();
    if (isValid) {
      if (currentFormat) {
        return moment(date, currentFormat).format(formatToChange);
      } else {
        return moment(date).format(formatToChange);
      }
    }
    return date;
  } catch (ex) {
    console.log(ex)
    return date;
  }
};

// method to decode filters from query string and convert it to object which will be feed to get category details api
// filterQuery: manufacturer:7 For All Mankind-2015,AG Adriano Goldschmied-2081::category_type:Activewear-2875::price:10000-25000-10000-25000
export const decodeFiltersFromQueryString = async (filterQuery) => {
  if (filterQuery) {
    // let mainFilterArr = decodeURIComponent(filterQuery)?.split("::") || [];
    let mainFilterArr = filterQuery.split("/");
    let attribute_code_arr = [];
    let attribute_value_arr = [];
    mainFilterArr.forEach(function (value, index) {
      if (index % 2 === 0) {
        attribute_code_arr.push(value);
      } else {
        attribute_value_arr.push(value);
      }
    });
    return (
      // mainFilterArr?.map((mainFilterStr) => {
      //   let mainFilterSplit = mainFilterStr.split(":") || [];
      //   let title = mainFilterSplit[0] || "";
      //   let itemsArr = mainFilterSplit[1]?.split(",") || [];
      attribute_value_arr?.map((mainFilterStr, index) => {
        let title = attribute_code_arr[index];
        let itemsArr = mainFilterStr.split("--") || [];

        itemsArr = itemsArr?.map((item) => {
          // let arr = item.split("||") || [];
          // let arr = item.split("_") || [];
          return {
            // id: arr[1] || "",
            // name: arr[0] || "",
            url_slug: item,
          };
        });
        let sort_order = 10;
        if (title !== "") {
          /* eslint-disable */
          title = title.replace(/\-/g, "_");
          sort_order = getFilterSortOrder(title);
        }

        return {
          choice_type: "multiple",
          title: "id_attribute_group|" + title,
          items: itemsArr,
          sort_order: sort_order
        };
      }) || []
    );
  } else {
    return [];
  }
};

// method to encode filters which will be appended to query string
// filterQueryObj: [
// {
// 	"choice_type":"multiple",
// 	"title":"id_attribute_group|manufacturer",
// 	"items":[
// 		{
// 			"id":"2015",
// 			"name":"7 For All Mankind"
// 		},
// 		{
// 			"id":"2081",
// 			"name":"AG Adriano Goldschmied"
// 		}
// 	]
// },
// {...}
// ]
//
export const encodeFiltersForQueryString = async (filterQueryArr) => {
  return (
    filterQueryArr
      .map((mainFilterObj) => {
        let encodeItems =
          mainFilterObj?.items
            ?.map((item) => item.name + "||" + item.id)
            ?.join(",") || "";
        let type = mainFilterObj?.title?.split("|")[1] || "";
        return type + ":" + encodeItems;
      })
      ?.join("::") || ""
  );
};

// method to merge applied filters
export const mergeAppliedFilters = async (allAppliedFilters, filterToMerge) => {
  let appliedFilterArr = [...allAppliedFilters];
  let filter1 = appliedFilterArr.find((i) => i.title === filterToMerge.title);
  if (filter1 && typeof filter1 === "object") {
    return appliedFilterArr.map((i) => {
      let attribute_code = i.title;
      attribute_code = attribute_code.replace("id_attribute_group|", "");
      let sort_order = getFilterSortOrder(attribute_code);
      if (i.title === filterToMerge.title) {
        return {
          choice_type: "multiple",
          title: i.title,
          items: i.items.concat(filterToMerge.items),
          sort_order: sort_order
        };
      }
      return i;
    });
  } else {
    let attribute_code = filterToMerge.title;
    attribute_code = attribute_code.replace("id_attribute_group|", "");
    let sort_order = getFilterSortOrder(attribute_code);
    appliedFilterArr.push({
      choice_type: "multiple",
      title: filterToMerge.title,
      items: filterToMerge.items,
      sort_order: sort_order
    });
    // console.log("appliedFilterArr:-");
    // console.log(appliedFilterArr);
    return appliedFilterArr;
  }
};

// method to remove filters=
export const removeFilters = async (allAppliedFilters, filterToRemove) => {
  // console.log("allAppliedFilters:-");
  // console.log(allAppliedFilters);
  // console.log("filterToRemove:-");
  // console.log(filterToRemove);
  let appliedFilterArr = [...allAppliedFilters];
  /* eslint-disable */
  let filterToRemoveTitle = filterToRemove.title.replace(/\-/g, "_");
  let filter1 = appliedFilterArr.find((i) => i.title === filterToRemoveTitle);
  if (filter1 && typeof filter1 === "object") {
    let updItems =
      filter1.items.filter(
        (i1) => !filterToRemove.items.map((i2) => i2.url_slug).includes(i1.url_slug)
      ) || [];

    if (updItems.length > 0) {
      appliedFilterArr.find((i) => i.title === filterToRemoveTitle).items =
        updItems;
      return appliedFilterArr;
    } else {
      return appliedFilterArr.filter((i) => i.title !== filterToRemoveTitle);
    }
  }
  return appliedFilterArr;
};

// method to return the array of only selected filter item id from query string
export const getSelectedFilterItemArr = (filterQuery, optional = false) => {
  if (filterQuery) {
    // let mainFilterArr = decodeURIComponent(filterQuery)?.split("::") || [];
    // mainFilterArr =
    //   mainFilterArr?.map((filter) => {
    //     let filterDetails = filter?.split(":");
    //     let filterItemstr = filterDetails[1] || "";
    //     return filterItemstr.split(",")?.map((i) => {
    //       let itemDetails = i.split("||");
    //       if (optional) {
    //         return {
    //           id: itemDetails[1] || "",
    //           name: itemDetails[0] || "",
    //           title: filterDetails[0] || "",
    //         };
    //       } else {
    //         return itemDetails[1] || "";
    //       }
    //     });
    //   }) || [];
    // return [].concat.apply([], mainFilterArr);
    let mainFilterArr = filterQuery.split("/");
    let attribute_code_arr = [];
    let attribute_value_arr = [];
    mainFilterArr.forEach(function (value, index) {
      if (index % 2 === 0) {
        attribute_code_arr.push(value);
      } else {
        attribute_value_arr.push(value);
      }
    });
    mainFilterArr =
      attribute_value_arr?.map((mainFilterStr, index) => {
        let title = attribute_code_arr[index];
        let itemsArr = mainFilterStr.split("--") || [];
        return itemsArr?.map((item) => {
          // let itemDetails = item.split("_") || [];
          // let id = itemDetails[1] || "";
          let filter_label = window.$("#" + title + "").find('label[for="cbitem-' + item + '"]').text() || "";
          if (optional) {
            return {
              // id: id,
              name: filter_label,
              title: title,
              url_slug: item,
            };
          } else {
            // return itemDetails[1] || "";
            return item;
          }
        });
      }) || [];
    return [].concat.apply([], mainFilterArr);
  } else {
    return [];
  }
};

// method to return the sort order for attribute codes
function getFilterSortOrder(attribute_code) {
  let sort_order = 10;
  if (attribute_code === "gender") {
    sort_order = 1;
  }
  else if (attribute_code === "age_group") {
    sort_order = 2;
  }
  else if (attribute_code === "manufacturer") {
    sort_order = 3;
  }
  else if (attribute_code === "category_type") {
    sort_order = 4;
  }
  else if (attribute_code === "size") {
    sort_order = 5;
  }
  else if (attribute_code === "discount_percent_range") {
    sort_order = 6;
  }
  else if (attribute_code === "summer_winter") {
    sort_order = 7;
  }
  else if (attribute_code === "filter_color") {
    sort_order = 8;
  }
  else if (attribute_code === "price") {
    sort_order = 9;
  }
  return sort_order;
}