import {
  RECENT_VIEWED_PRODUCTS,
  RECENT_VIEWED_PRODUCTS_COUNT,
} from "../../utils/Constant";
import {
  GET_RECENTLY_VIEWED_PRODUCTS,
  ADD_RECENTLY_VIEWED_PRODUCTS,
  CLEAR_RECENTLY_VIEWED_PRODUCTS,
} from "../actions/types";

const initialState = {
  products: [],
};

/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_RECENTLY_VIEWED_PRODUCTS:
      let rvProducts = JSON.parse(localStorage.getItem(RECENT_VIEWED_PRODUCTS)) || [];

      return {
        products: rvProducts,
      };

    case ADD_RECENTLY_VIEWED_PRODUCTS:
      let product = action?.payload?.product; // product to add
      if(product) {
        let parsedRVProducts = JSON.parse(localStorage.getItem(RECENT_VIEWED_PRODUCTS)) || []; // already added products
        // if same product is already exist in collection then filter the same
        let fProducts = parsedRVProducts?.filter((item) => item?.id_product && item?.id_product !== product?.id_product); 
  
        // push the current product in the array
        fProducts.unshift(product);
  
        // sliced down the products length to allowed length of viewed products collection
        let sProducts = fProducts?.slice(0, +RECENT_VIEWED_PRODUCTS_COUNT) || [];
  
        // store the products array in local storage
        localStorage.setItem(RECENT_VIEWED_PRODUCTS, JSON.stringify(sProducts));
  
        return {
            products: sProducts
        };
      }
      else {
          return {
              ...state
          };
      }
      

    case CLEAR_RECENTLY_VIEWED_PRODUCTS:
      return {
        products: [],
      };
    default:
      return state;
  }
}
