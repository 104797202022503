import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const {pathname} = useLocation();

  // console.log(location)

  useEffect(() => {
    // window.onhashchange = function() {
    //   window.location.href = window.location.href;
    // };
    window.scrollTo({
      top: 0,
      behavior: "instant"
    });
  }, [pathname]);

  return null;
}