import { ADD_CART_COUNT, CLEAR_CART_COUNT, GET_CART_COUNT } from "./types";

// ADD Cart Count
export const addCartCount = (cartCount) => {
  return {
    type: ADD_CART_COUNT,
    payload: { cartCount },
  };
};

// RETURN Cart Count
export const returnCartCount = () => {
    return {
        type: GET_CART_COUNT
    }
}


// CLEAR Cart Count
export const clearCartCount = () => {
    return {
        type: CLEAR_CART_COUNT
    }
}