import config from "../config/app";
import * as API from "../utils/ApiHelper";

const { isProd } = config;

const API_ENDPOINT = isProd
  ? config.production.api_endpoint
  : config.development.api_endpoint;

// Add product to Wishlist
export const appAddToWishlist = (productId) => {
  const url = `${API_ENDPOINT}/appAddToWishlist`;

  // preparing post data to send
  let data = {
    product_id: productId,
  };
  return API.post(url, data);
};

// Remove product from Wishlist
export const appRemoveWishlist = (productId) => {
  const url = `${API_ENDPOINT}/appRemoveWishlist`;

  // preparing post data to send
  let data = {
    product_id: productId,
    id_product_attribute: "",
    request_type: "remove",
  };
  return API.post(url, data);
};

export const appGetWishlist = (data) => {
  const url = `${API_ENDPOINT}/appGetWishlist`;
  return API.post(url, data);
};

export const appGetFriendsWishlist = (data) => {
  const url = `${API_ENDPOINT}/appGetFriendsWishlist`;

  return API.post(url, data);
};

export const appAddWishlistVisiblity = (data) => {
  const url = `${API_ENDPOINT}/appAddWishlistVisiblity`;

  return API.post(url, data);
};
