import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { ModalBody } from "react-bootstrap";
import { clearErrors } from "../../store/actions/errorActions";
import "./AuthModal.css";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import OTPLogin from "./OTPLogin";
import Signup from "./Signup";
import EmailAuth from "./EmailAuth";
import { clearPopups } from "../../store/actions/popupActions";

Modal.setAppElement("body");

function AuthModal() {
  const dispatch = useDispatch();

  const authState = useSelector((state) => state.auth);

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoginActive, setIsLoginActive] = useState(true);
  const [isSignupActive, setIsSignupActive] = useState(false);
  const [isForgotPwdActive, setIsForgotPwdActive] = useState(false);
  const [isOTPLoginActive, setIsOTPLoginActive] = useState(false);
  const [isEmailAuthActive, setIsEmailAuthActive] = useState(false);

  const [sendOtpResponse, setSendOtpResponse] = useState(null);
  const [otpUserName, setOtpUserName] = useState("");

  useEffect(() => {
    if (authState?.token && authState?.isAuthenticated && authState?.user) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [authState]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalHeader, setModalHeader] = useState("LOGIN");
  const [activeForm, setActiveForm] = useState("login");
  const [appleResponse, setappleResponse] = useState({});

  const toggle = () => {
    //clear errors
    dispatch(clearErrors());

    if (isModalOpen) {
      dispatch(clearPopups());
    }
    setIsModalOpen(!isModalOpen);
  };

  const popupState = useSelector((state) => state.popup);

  useEffect(() => {
    if (popupState?.popupId === "login") {
      toggleAuthFormCb("login");
      setIsModalOpen(true);
    } else if (popupState?.popupId === "signup") {
      toggleAuthFormCb("signup");
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
  }, [popupState]);

  const customStyles = {
    // content: {
    //   top: '50%',
    //   left: '50%',
    //   right: 'auto',
    //   bottom: 'auto',
    //   marginRight: '-50%',
    //   transform: 'translate(-50%, -50%)',
    // },
    overlay: { zIndex: "999999", backgroundColor: "rgb(0 0 0 / 75%)" },
  };

  const toggleAuthFormCb = (formName) => {
    if (formName === "login") {
      setIsLoginActive(true);
      setIsForgotPwdActive(false);
      setIsOTPLoginActive(false);
      setIsSignupActive(false);
      setIsEmailAuthActive(false);
      setModalHeader("LOGIN");
      setActiveForm("login");
    } else if (formName === "forgotpwd") {
      setIsLoginActive(false);
      setIsForgotPwdActive(true);
      setIsOTPLoginActive(false);
      setIsSignupActive(false);
      setIsEmailAuthActive(false);
      setModalHeader("FORGOT YOUR PASSWORD");
      setActiveForm("forgotpwd");
    } else if (formName === "otplogin") {
      setIsLoginActive(false);
      setIsForgotPwdActive(false);
      setIsOTPLoginActive(true);
      setIsSignupActive(false);
      setIsEmailAuthActive(false);
      setModalHeader("LOGIN WITH OTP");
      setActiveForm("otplogin");
    } else if (formName === "signup") {
      setIsLoginActive(false);
      setIsForgotPwdActive(false);
      setIsOTPLoginActive(false);
      setIsSignupActive(true);
      setIsEmailAuthActive(false);
      setModalHeader("SIGN UP");
      setActiveForm("signup");
    } else if (formName === "emailauth") {
      setIsLoginActive(false);
      setIsForgotPwdActive(false);
      setIsOTPLoginActive(false);
      setIsSignupActive(false);
      setIsEmailAuthActive(true);
      setModalHeader("Enter Email");
      setActiveForm("emailauth");
    }
  };

  const setVerifyOTPResponse = (res, user) => {
    setSendOtpResponse(res);
    setOtpUserName(user);
  };

  const saveAppleResponseFunction = (res) =>{
    setappleResponse(res);
    toggleAuthFormCb('emailauth')
  }

  return (
    <>
      <Modal
        isOpen={isModalOpen && !isAuthenticated}
        toggle={toggle}
        style={customStyles}
        onRequestClose={toggle}
        shouldCloseOnOverlayClick={true}
        id="authModal"
      >
        <ModalHeader
          className={`custom-auth-header custom-header${"-" + activeForm}`}
        >
          {!isLoginActive && (
            <button
              onClick={(e) => toggleAuthFormCb("login")}
              className="btn-blank"
            >
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/icons/right-arrow-angle.png"
                }
                className="back-btn-newsletter"
                alt=""
              />
            </button>
          )}

          <h3>{modalHeader}</h3>
          <button onClick={toggle} className="btn-blank">
            <img
              src={
                process.env.PUBLIC_URL + "/assets/images/icons/Cross-Button.png"
              }
              className="close-btn-newsletter"
              alt=""
            />
          </button>
        </ModalHeader>
        <ModalBody>
          {isLoginActive && (
            <Login
              active={isLoginActive}
              toggleAuthFormCb={toggleAuthFormCb}
              setVerifyOTPResponse={setVerifyOTPResponse}
              saveAppleResponseFunction={saveAppleResponseFunction}
            />
          )}

          {isForgotPwdActive && (
            <ForgotPassword
              active={isForgotPwdActive}
              toggleAuthFormCb={toggleAuthFormCb}
            />
          )}

          {isOTPLoginActive && (
            <OTPLogin
              active={isOTPLoginActive}
              toggleAuthFormCb={toggleAuthFormCb}
              otpResponse={sendOtpResponse}
              userName={otpUserName}
              setVerifyOTPResponse={setVerifyOTPResponse}
            />
          )}

          {isSignupActive && (
            <Signup
              active={isSignupActive}
              toggleAuthFormCb={toggleAuthFormCb}
            />
          )}

          {isEmailAuthActive && (
            <EmailAuth
              active={isEmailAuthActive}
              toggleAuthFormCb={toggleAuthFormCb}
              appleResponse={appleResponse}
            />
          )}

        </ModalBody>
      </Modal>
    </>
  );
}
export default AuthModal;
