import React from 'react';
import config from "../../config/app.js";

function FollowUs() {
    return (
      <div className="follow-us d-dsk">
        <div className="foot-heading">
          <h4>Follow us:</h4>
        </div>
        <ul className="social-media">
          <li>
            <a href="https://www.facebook.com/Darveys" target={"_blank"} rel="noreferrer">
              <img src={config.skinURL + "/assets/images/icons/Facebook.svg"} alt="" />
            </a>
          </li>
          <li>
            <a href="http://instagram.com/darveys" target={"_blank"} rel="noreferrer">
              <img src={config.skinURL + "/assets/images/icons/Instagram.svg"} alt="" />
            </a>
          </li>
          <li>
            <a href="http://www.pinterest.com/darveys/" target={"_blank"} rel="noreferrer">
              <img src={config.skinURL + "/assets/images/icons/Pinstrest.svg"} alt="" />
            </a>
          </li>
          <li>
            <a href="http://www.twitter.com/Darveyscom" target={"_blank"} rel="noreferrer">
              <img src={config.skinURL + "/assets/images/icons/Twitter.svg"} alt="" />
            </a>
          </li>
        </ul>
      </div>
    );
}

export default FollowUs
