// Types for Auth Actions
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

// Types for Errors
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

//Types for Recently Viewed Products
export const ADD_RECENTLY_VIEWED_PRODUCTS = "ADD_RECENTLY_VIEWED_PRODUCTS";
export const GET_RECENTLY_VIEWED_PRODUCTS = "GET_RECENTLY_VIEWED_PRODUCTS";
export const CLEAR_RECENTLY_VIEWED_PRODUCTS = "CLEAR_RECENTLY_VIEWED_PRODUCTS";

// Types for Modals
export const ADD_OPEN_POPUP = "ADD_OPEN_POPUP"
export const CLEAR_OPEN_POPUP = "CLEAR_OPEN_POPUP"

// Types for Cart Count
export const GET_CART_COUNT = "GET_CART_COUNT";
export const ADD_CART_COUNT = "ADD_CART_COUNT";
export const CLEAR_CART_COUNT = "CLEAR_CART_COUNT";


