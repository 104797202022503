import { ADD_OPEN_POPUP, CLEAR_OPEN_POPUP } from "../actions/types";

const initialState = {
  popupId: ''
};

/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_OPEN_POPUP:
      return {
        popupId: action.payload.popupId
      };

    case CLEAR_OPEN_POPUP:
      return {
        popupId: ''
      };
    default:
      return state;
  }
}
