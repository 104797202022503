import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";

function MobileHeaderSideSubMenu({ subMenu, showMainMenuCb, modalToggleCb }) {
  const [searchDesigner, setSearchDesigner] = useState("");
  const navigate = useNavigate();
  const onCategoryClick = (url) => {
    navigate(url);
    modalToggleCb();
  };

  return (
    <div
      className="indp"
      id={`submenu-${subMenu?.name?.toString()?.toLowerCase()}`}
    >
      <div className="inner-heading">
        <h3 onClick={showMainMenuCb}>{subMenu?.name}</h3>
      </div>

      {/* Search for designer menu */}
      {subMenu?.name?.toString()?.toLowerCase() === "designers" && (
        <div className="search-box-input-cs">
          <img
            src={
              process.env.PUBLIC_URL +
              "/assets/images/icons/Grey-Search-Icon.png"
            }
            alt=""
            className="close-btn-newsletter"
          />
          
            <Form.Control
              type="text"
              autoComplete="off"
              className="form-control"
              value={searchDesigner}
              placeholder={`Search`}
              onChange={(e) => setSearchDesigner(e.target.value)}
            />          

          {/* <input
            type="text"
            className="form-control"
            value={searchDesigner}
            placeholder={`Search`}
            onChange={(e) => setSearchDesigner(e.target.value)}
          /> */}
          
          {searchDesigner && (
            <img
              src={
                process.env.PUBLIC_URL +
                "/assets/images/icons/Cross-Button-new.png"
              }
              className="header-icon-cross img-fluid d-block search-cross-btn"
              alt=""
              onClick={(e) => {
                setSearchDesigner("");
              }}
            />
          )}
        </div>
      )}

      {subMenu?.second_children && subMenu?.second_children?.length > 0 && (
        <div className="accordion" id={`menu${subMenu?.name}`}>
          {subMenu?.second_children
            ?.filter((i) =>
              subMenu?.name?.toString()?.toLowerCase() === "designers" &&
              searchDesigner?.length > 0
                ? i?.name
                    ?.toString()
                    .toLowerCase()
                    .indexOf(searchDesigner.toLowerCase()) > -1
                : true
            )
            ?.map((secondChild, sIdx) => {
              return (
                <div key={`sub-menu-item-${sIdx}`}>
                  {secondChild?.children &&
                  secondChild?.children?.length > 0 ? (
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id={`${secondChild?.name}menu`}
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#${secondChild?.name}collapse`}
                          aria-expanded="false"
                          aria-controls={`${secondChild?.name}collapse`}
                        >
                          {secondChild?.name}
                        </button>
                      </h2>
                      <div
                        id={`${secondChild?.name}collapse`}
                        className="accordion-collapse collapse"
                        aria-labelledby={`${secondChild?.name}menu`}
                        data-bs-parent={`#menu${subMenu?.name}`}
                      >
                        {secondChild?.children &&
                        secondChild?.children?.length > 0 ? (
                          <div className="accordion-body">
                            <ul className="mobile-nav-items">
                              {secondChild?.children?.map(
                                (child, childIndex) => {
                                  return (
                                    <>
                                      {child?.children &&
                                      child?.children?.length > 0 ? (
                                        <>
                                          <div
                                            className="accordion"
                                            id={`submenu${child?.name
                                              ?.toLowerCase()
                                              ?.replace(" ", "_")}`}
                                          >
                                            <div className="accordion-item">
                                              <h2
                                                className="accordion-header"
                                                id={`${child?.name
                                                  ?.toLowerCase()
                                                  ?.replace(" ", "_")}submenu`}
                                              >
                                                <button
                                                  className="accordion-button collapsed"
                                                  type="button"
                                                  data-bs-toggle="collapse"
                                                  data-bs-target={`#${child?.name
                                                    ?.toLowerCase()
                                                    ?.replace(
                                                      " ",
                                                      "_"
                                                    )}collapse`}
                                                  aria-expanded="false"
                                                  aria-controls={`${child?.name
                                                    ?.toLowerCase()
                                                    ?.replace(
                                                      " ",
                                                      "_"
                                                    )}collapse`}
                                                >
                                                  {child?.name}
                                                </button>
                                              </h2>
                                              <div
                                                id={`${child?.name
                                                  ?.toLowerCase()
                                                  ?.replace(" ", "_")}collapse`}
                                                className="accordion-collapse collapse"
                                                aria-labelledby={`${child?.name
                                                  ?.toLowerCase()
                                                  ?.replace(" ", "_")}submenu`}
                                                data-bs-parent={`#submenu${child?.name
                                                  ?.toLowerCase()
                                                  ?.replace(" ", "_")}`}
                                              >
                                                <div className="accordion-body">
                                                  <ul className="mobile-nav-items">
                                                    {child?.children?.map(
                                                      (thirdChild, sIdx2) => {
                                                        return (
                                                          <li
                                                            className="inner-nav-items"
                                                            key={`subSubSubMenuitem-${sIdx2}`}
                                                          >
                                                            <span
                                                              onClick={(e) =>
                                                                onCategoryClick(
                                                                  `/catalog/${thirdChild?.url_path}`
                                                                )
                                                              }
                                                            >
                                                              {thirdChild?.name}
                                                            </span>
                                                          </li>
                                                        );
                                                      }
                                                    )}
                                                  </ul>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <li
                                          className="inner-nav-items"
                                          key={`subSubMenuitem-${childIndex}`}
                                        >
                                          <span
                                            onClick={(e) =>
                                              onCategoryClick(
                                                `/catalog/${child?.url_path}`
                                              )
                                            }
                                          >
                                            {child?.name}
                                          </span>
                                        </li>
                                      )}
                                    </>
                                  );
                                }
                              )}
                            </ul>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ) : (
                    <p className="designer-item">
                      <span className="text-black"
                        onClick={(e) =>
                          onCategoryClick(`/catalog/${secondChild?.url_path}`)
                        }
                      >
                        {secondChild?.name}
                      </span>
                    </p>
                  )}
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
}

export default MobileHeaderSideSubMenu;
