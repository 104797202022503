import { useState } from "react";
// import OtpInput from "react-otp-input";
// import { toast } from "react-toastify";
import { appVerifyResetPasswordOTP } from "../../services/AccountServices";
import { REGEX_OTP, PLEASE_WAIT_TXT } from "../../utils/Constant";

function VerifyOTP({
  customerId,
  handleSetStep,
  handleSetCustomerId,
  handleSetToken,
  message,
}) {
  const [otp, setOtp] = useState("");
  const [hasErrored, setHasErrored] = useState(false);
  // const [isDisabled] = useState(false);
  const [numInputs] = useState(6);
  const [isSubmitting, SetIsSubmitting] = useState(false);

  const handleChange = (otp) => setOtp(otp);

  // const clearOtp = () => setOtp("");

  const handleSubmit = (e) => {
    e.preventDefault();

    // validate otp
    if (!REGEX_OTP.test(otp)) {
      setHasErrored(true);
    } else {
      SetIsSubmitting(true);
      appVerifyResetPasswordOTP(customerId, otp)
        .then((res) => {
          res = JSON.parse(res?.data);
          if (res?.status === "success") {
            handleSetToken(res?.token);
            handleSetCustomerId(res?.customer_id);
            handleSetStep(3);
          } else {
            setHasErrored(true);
            // toast.error(res?.message || "");
          }
        })
        .finally(() => SetIsSubmitting(false));
    }
  };

  return (
    <div className="verify-otp-container">
      <form onSubmit={handleSubmit}>
        <div className="form-input contc-frm">
          <p style={{ marginBottom: "10px",fontSize: "13px" }}>
            {message}
          </p>
        </div>        
          <div className="form-input contc-frm mt-25">
            <div className="input-group">              
              <input
                type="text"
                placeholder="Enter OTP"
                value={otp}
                onChange={(e) => handleChange(e.target.value)}
                disabled={isSubmitting}
              />
            </div>
          </div>
        {hasErrored ? (
          <div className="validation-advice has-error mt-20">
            <span className="text-danger">{"Please enter valid OTP."}</span>
          </div>
        ) : null}

        {/* <div className="btn-row mt-20"> */}
          {/* <button
            className="btn btn-outline-primary"
            type="button"
            disabled={isDisabled || isSubmitting || otp.trim() === ""}
            onClick={clearOtp}
          >
            Clear
          </button> */}
          <button
            className="btn btn-primary mt-20"
            disabled={isSubmitting || otp.length < numInputs}
          >
            {isSubmitting? PLEASE_WAIT_TXT : "Verify"}
          </button>
        {/* </div> */}
      </form>
    </div>
  );
}

export default VerifyOTP;
