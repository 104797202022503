import React, { useState } from "react";
import Copyright from "./Copyright";
import FollowUs from "./FollowUs";
import Newsletter from "./Newsletter";
import PaymentMode from "./PaymentMode";
// import ReadMoreContent from "./ReadMoreContent";
import WidgetLinks from "./WidgetLinks";

function Footer({ seoDetails }) {
  const [readMoreContentProps, setReadMoreContentProps] = useState({
    display: "none"
  });
  const [readMoreStyle, setReadMoreStyle] = useState({
    display: "inline-block"
  });
  const [readLessStyle, setReadLessStyle] = useState({
    display: "none",
  });

  const readmoreFunction = () => {
    setReadMoreStyle({
      display: "none",
    });
    setReadLessStyle({
      display: "inline-block",
    });
    setReadMoreContentProps({
      display: "block",
    });
  };

  const readlessFunction = () => {
    setReadMoreStyle({});
    setReadLessStyle({
      display: "none",
    });
    setReadMoreContentProps({
      display: "none",
    });
  };

  return (
    <div id="footer">
      <footer className="bg-light">
        <div className="container-fluid">
          <div className="row">
            {/* Widget links */}
            <WidgetLinks />

            <div className="col-md-6">
              {/* Follow us */}
              <FollowUs />

              {/* Payment Modes */}
              <PaymentMode />
            </div>
          </div>

          {/* Read More Section */}
          <div className="row">
            <div className="col-md-12">
              <div className="rmb">
                <button
                  id="show"
                  onClick={readmoreFunction}
                  style={readMoreStyle}
                >
                  Read More +
                  {/* <FaPlus /> */}
                </button>
                <button
                  id="hide"
                  onClick={readlessFunction}
                  style={readLessStyle}
                >
                  Read Less -
                  {/* <FaMinus /> */}
                </button>
              </div>
            </div>
          </div>
          {/* /Read More Section */}
        </div>
      </footer>

      {/* ReadMore Content */}
      <div
        className="footer-read-more"
        id="footer_read_more"
        style={readMoreContentProps}
      >
        {/* <ReadMoreContent /> */}
        <div className="seo-details" dangerouslySetInnerHTML={{ __html: `${seoDetails.description}` }}>
        </div>
      </div>

      {/* Copyright */}
      <Copyright />

      {/* NEWSLETTER */}
      <Newsletter />
    </div>
  );
}

export default Footer;
