import React from "react";
import AppleLogin from "react-apple-login";

function AppleAuth({saveAppleResponse}) {
  const appleResponse = (res) =>{
    if (!res.error) {
      saveAppleResponse(res);
    }
  }

  return (
    <div>      
      <AppleLogin
        clientId={"com.darveys.app.sid"}
        redirectURI={"https://www.darveys.com"}
        responseType={"code"}
        responseMode={"query"}
        usePopup={true}
        callback={appleResponse}
        render={renderProps => (
          <a onClick={renderProps.onClick} disabled={renderProps.disabled} href="#0"><img src="../assets/images/icons/apple.png" alt="" /></a>
        )}
        designProp={{
          height: 30,
          width: 140,
          color: "black",
          border: false,
          type: "sign-in",
          border_radius: 15,
          scale: 1,
          locale: "en_US",
        }}
      />
    </div>
  );
}

export default AppleAuth;
