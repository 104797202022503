function CrossButton(props) {
  return (
    <button className="btn-blank" {...props}>
      <img
        src={process.env.PUBLIC_URL + '/assets/images/icons/Cross-Button.png'}
        className="close-btn-input close-btn-newsletter"
        alt=""
      />
    </button>
  );
}

export default CrossButton;
