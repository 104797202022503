import { LS_CAT_PRODS_KEY } from "./Constant";
import React, { useContext } from "react";
import { UNSAFE_NavigationContext } from "react-router-dom";

export default function InterceptRoute() {
  const navigation = useContext(UNSAFE_NavigationContext).navigator;
  React.useLayoutEffect(() => {
    if (navigation) {
      navigation.listen(({ action, location }) => {
        if (action === "PUSH" && location.pathname.includes("/catalog")) {
          localStorage.removeItem(LS_CAT_PRODS_KEY);
        }
      });
    }
  }, [navigation]);

  // useEffect(() => {
  //   // Step 1: intercept route change
  //   // navigate.listen((location) => {
  //   //   // Step 2: if next url is catalog and not back pressed then clear product data from localstorage
  //   //   if(navigate.action === 'PUSH' && location.pathname.includes('/catalog')) {
  //   //       localStorage.removeItem(LS_CAT_PRODS_KEY)
  //   //   }
  //   // });

  //   console.log(location);
  // }, [location]);

  return null;
}
