import { useEffect, useState } from "react";

import "./MoveTop.css";

const imageUrl = process.env.PUBLIC_URL + "/assets/images/icons/back-top.png";

function MoveTop() {
  const [showMoveUp, setShowShowMoveUp] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 300) {
        setShowShowMoveUp(true);
      } else {
        setShowShowMoveUp(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleMoveUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  };

  return (
    <>
      <div
        id="back-top"
        className="moveTop"
        style={{ display: showMoveUp ? "block" : "none" }}
        onClick={handleMoveUp}
      >
        {/* <a href="#0" className="moveTop__btn" alt="" style={{backgroundImage:  `url(${imageUrl})`}}>{""}</a> */}
        <span
          className="moveTop__btn"
          style={{ backgroundImage: `url(${imageUrl})` }}
        >
          {""}
        </span>
      </div>
    </>
  );
}

export default MoveTop;
