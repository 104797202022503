import Skeleton from "@mui/material/Skeleton";

function Placeholder(props) {
    return (
      <>
        <Skeleton {...props} />
      </>
    );
}

export default Placeholder
