import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { ModalBody } from "react-bootstrap";
import { useQuery } from "react-query";

import "./SearchModal.css";
import { appGetSearchResults } from "../../services/CatalogServices";
import { useNavigate } from "react-router";
import { MOBIEL_VIEW_WIDTH } from "../../utils/Constant";
import CrossButton from "../Common/CrossButton";
import SearchCategoryAccordion from "./SearchCategoryAccordion";
import { useSearchParams } from "react-router-dom";


function SearchModal(props) {
  const [isModelOpen, setIsModalOpen] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [searchResults, setSearchResults] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  // get query strings
  // let searchParams = new URLSearchParams(props.location?.search);
  let searchQs = searchParams?.get("search") || "";

  useEffect(() => {
    setSearchTerm(searchQs);
  }, [searchQs]);

  function handleWindowSize() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSize);
    return () => {
      window.removeEventListener("resize", handleWindowSize);
    };
  }, []);

  let isMobile = width <= MOBIEL_VIEW_WIDTH;

  const toggle = () => {
    setIsModalOpen(!isModelOpen);
  };

  const onRedirectUser = (url,searchTerm) => {
    if(searchTerm !== ""){
      navigate(url);
    }else{
      navigate(`/catalog/all`);
    }    
    toggle();
    return;
  };

  const customStyles = {
    overlay: { zIndex: "999999", backgroundColor: "rgb(0 0 0 / 75%)" },
  };

  const { data } = useQuery(
    ["searchData", searchTerm],
    () =>
      appGetSearchResults(searchTerm).then((res) => {
        res = JSON.parse(res?.data);
        return res;
      }),
    {
      enabled: isModelOpen,
    }
  );

  useEffect(() => {
    if (data?.status === "success") {
      setSearchResults(data.results || {});
    }
  }, [data]);

  return (
    <>
      {isMobile ? (
        <button onClick={toggle} className="mb-wishlist btn-blank" style={{zIndex:"1",padding:"0.5em",margin:"-0.5em"}}>
          <img
            src={
              process.env.PUBLIC_URL +
              "/assets/images/icons/Normal-Search-button-icon.png"
            }
            alt=""
          />
        </button>
      ) : (
        <>
          <div className="serch-header">
            <img
              src={
                process.env.PUBLIC_URL + "/assets/images/icons/search-icon.png"
              }
              className="header-icon-search img-fluid d-block"
              alt=""
            />
            <input
              type="text"
              className="form-control search-icon"
              id="search-dbl-click"
              value={searchTerm}
              onChange={(e) => e.preventDefault()}
              placeholder="Search for product"
              onKeyDown={toggle}
              onClick={toggle}
              readOnly={true}
            />
            {searchTerm && (
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/icons/Cross-Button-new.png"
                }
                className="header-icon-cross img-fluid d-block"
                alt=""
                onClick={(e) => setSearchTerm("")}
              />
            )}
          </div>
        </>
      )}

      <Modal
        isOpen={isModelOpen}
        toggle={toggle}
        style={customStyles}
        onRequestClose={() => setIsModalOpen(false)}
        shouldCloseOnOverlayClick={true}
        id="search-modal"
      >
        <ModalHeader>
          <div className="search-box-heading logo-mb">
          {isMobile ? (  
            <div className="input-group">
              <div
              onClick={() => setIsModalOpen(false)}
              style={{padding:"1em",margin:"-1em"}}
              >
                <img                  
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/icons/angle-arrow-left.png"
                  }
                  className="input-icon-search header-icon-search img-fluid d-block"
                  alt=""
                  style={{maxWidth:"100%"}}
                />
              </div>
                <form onSubmit={
                  (e) =>
                    onRedirectUser(
                      `/catalog?search=${searchTerm}`,searchTerm
                    )}>
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="form-control search-icon deletable"
                    placeholder="Search for product"
                    onKeyUp={(e) => setSearchTerm(e.target.value)}
                    autoFocus={true}
                  />
                </form>
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/icons/Normal-Search-button-icon.png"
                  }
                  className="header-icon-cross img-fluid d-block search-cross-btn"
                  alt=""
                />
            </div>
          )
          :
          (
            <span>
              <img
                src={process.env.PUBLIC_URL + "/assets/images/logo-dark.svg"}
                className="img-fluid d-block mx-auto"
                alt=""
              />
            </span>
          )}
          </div>
        </ModalHeader>

        <ModalBody>
          <div className="search-box">
            <div className="search-panel">
              <div className="col-md-4 mx-auto">
              {!isMobile && (
                <>
                <div className="input-group">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/icons/search-icon.png"
                    }
                    className="input-icon-search header-icon-search img-fluid d-block"
                    alt=""
                  />
                      <form style={{width:"100%"}} onSubmit={
                        (e) =>
                          onRedirectUser(
                            `/catalog?search=${searchTerm}`,searchTerm
                          )}>
                        <input
                          type="text"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                          className="form-control search-icon deletable"
                          placeholder="Search for product"
                          onKeyUp={(e) => setSearchTerm(e.target.value)}
                          autoFocus={true}
                        />
                      </form>
                  {searchTerm && (
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/icons/Cross-Button-new.png"
                      }
                      className="header-icon-cross img-fluid d-block search-cross-btn"
                      alt=""
                      onClick={(e) => {
                        setSearchTerm("");
                      }}
                    />
                  )}
                </div>
                <CrossButton onClick={() => setIsModalOpen(false)} />
                </>
              )}  

                {/* if search term and search result is not empty  */}
                <ul className="search-list text-left">
                  {searchTerm && (
                    <li 
                      key="type0" 
                      onClick={(e) =>
                        onRedirectUser(
                          `/catalog?search=${searchTerm}`
                        )
                      }  
                      style={{ cursor: "pointer" }}>
                        <span>{searchTerm}</span>
                    </li>
                  )}
                  {searchTerm && searchResults?.products?.length > 0 && (                    
                      searchResults?.type?.map((type, index) => {
                        return (
                          <>
                            {index !== 0 ?
                            <li
                              key={"type" + index}
                              onClick={(e) =>
                                onRedirectUser(
                                  `/catalog?search=${type?.search_key}`
                                )
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <span>{type?.value}</span>
                            </li>
                            :
                            ""
                            }
                          </>
                        );
                      })
                  )}  
                </ul>
                {searchTerm && searchResults?.products?.length > 0 && (
                  <>
                    <div className="row">
                      <div className="col-md-11 mx-auto">
                        <div className="row">
                          {searchResults?.products?.map((product, index) => {
                            return (
                              <div className="col-md-4 col-4" key={"prod" + index}>
                                <div className="searc-img-box text-center mt-20">
                                  <span
                                    onClick={(e) =>
                                      onRedirectUser(`/product/${product?.url_path}`)
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    <img
                                      src={product.image}
                                      className="img-fluid mx-auto d-block"
                                      alt=""
                                    />
                                  </span>

                                  <h6 className="mt-10">
                                    <span
                                      onClick={(e) =>
                                        onRedirectUser(
                                          `/product/${product?.url_path}`
                                        )
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      {product.manufacturer}{" "}
                                    </span>
                                  </h6>
                                  <p className="truncate">{product.name}</p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {/* if search term and search result is empty */}
                {/* {searchTerm &&
                  (!searchResults ||
                    !searchResults?.products ||
                    searchResults?.products?.length === 0) && (
                    <div className="empty-search-result mx-auto">
                      <p className="empty-search-result-head">
                        Search results for : '{searchTerm}'
                      </p>
                      <p className="empty-search-result-middle">
                        Showing all 0 results
                      </p>
                      <p className="empty-search-result-products">
                        No products found which match your selection.
                      </p>
                    </div>
                  )} */}

                {/* if search input is empty */}
                {!searchTerm && (
                  <SearchCategoryAccordion modalToggle={toggle} />
                )}
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

export default SearchModal;
