import React from "react";
import { isIOS } from "react-device-detect";

const app_link = isIOS ? "https://apps.apple.com/in/app/darveys-luxury-shopping-india/id1460138461" : "https://play.google.com/store/apps/details?id=com.darveys.app";

function DownloadApp({removeDownloadPopup}) {

    const setCookie = (cname, cvalue, exdays) => {
        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        removeDownloadPopup();
    }

    return (
        <div className="grey-strip-top">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-3">
                        <div className="d-flex align-items-center">
                            <img src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/Cross-Button-new.png"
                            } className="img-fluid d-block crs-btn-tstrp" alt="" onClick={() => setCookie("close_download_app", true, 7)} />
                            <img src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/app-dw.png"
                            } className="img-fluid d-block mx-auto dlogo" alt="" />
                        </div>
                    </div>
                    <div className="col-9">
                        <div className="d-flex align-items-center">
                            <div className="text">
                                <h5>Revolutionising luxury fashion shopping</h5>
                                <span><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star-half-alt"></i></span>
                            </div>
                            <div className="button">
                                <a href={app_link} rel="noreferrer" className="dwnld-btn">Download App</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DownloadApp;