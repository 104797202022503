import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../store/actions/authActions";
import "./UserMenu.css";

function UserMenu() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const authState = useSelector((state) => state.auth);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userName, setUserName] = useState("");

  useEffect(() => {
    if (authState?.token && authState?.isAuthenticated && authState?.user) {
      setIsAuthenticated(true);
      setUserName(authState?.user?.first_name || "");
    } else {
      setIsAuthenticated(false);
      setUserName("");
    }
  }, [authState]);

  const onLogOut = () => {
    dispatch(logout());
    /**
     * BOC
     * Support #138655-Fwd: Checkout Static Pages
     * author Maanik Arya 
     * date 07-Jun-2023
     * use_of_code: Redirect to home page after logout.
     */
    navigate("/");
    /**
     * EOC
     */
  };

  return (
    <>
      {isAuthenticated === false ? null : (
        <Dropdown id="user-menu">
          <Dropdown.Toggle
            variant="blank"
            id="dropdown-basic"
            className="login-text dropdown-toggle show"
          >
            <img
              src={process.env.PUBLIC_URL + "/assets/images/icons/Users.svg"}
              alt=""
            />{" "}
            Hi {userName}!
          </Dropdown.Toggle>

          <Dropdown.Menu style={{ zIndex: "22222" }}>
            <Dropdown.Item onClick={() => navigate("/accounts/profile")}>
              My Profile
            </Dropdown.Item>
            <Dropdown.Item onClick={() => navigate("/accounts/order-history")}>
              My Order History
            </Dropdown.Item>
            <Dropdown.Item onClick={() => navigate("/accounts/gift-card")}>
              My Gift Card
            </Dropdown.Item>
            <Dropdown.Item onClick={() => navigate("/accounts/rewards")}>
              My Rewards
            </Dropdown.Item>
            <Dropdown.Item onClick={() => navigate("/wishlist")}>
              My Wishlist
            </Dropdown.Item>
            <Dropdown.Item onClick={() => navigate("/accounts/address")}>
              Address Book
            </Dropdown.Item>
            <Dropdown.Item onClick={onLogOut}>Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
}

export default UserMenu;
